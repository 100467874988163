import $ from "jquery";
import "magnific-popup";
import "slick-carousel";

export default class UIBindings {
    log() {
        console.log('binding');
    }
    formSubmitButton() {
        $(document).on('submit', 'form', function () {
            let submitText = $(this).find('[data-submit-text]');
            let submittingText = $(this).find('[data-submitting-text]');
            let btn = submitText.closest('button');
            
            submittingText.removeClass('d-none');
            submitText.addClass('d-none');
            btn.prop('disabled', true);
        });
    }
    magnificPopup () {
        let image = $('[mfp-type="image"]');
        let gallery = $('[mfp-type="gallery"]');

        if (image.length > 0) {
            image.magnificPopup({type: "image"});
        }
        if (gallery.length > 0) {
            gallery.magnificPopup({
                type: 'image',
                closeOnContentClick: false,
                closeBtnInside: true,
                mainClass: 'mfp-with-zoom mfp-img-mobile',
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    tCounter: '%curr% of %total%',
                    preload: [0, 2] // Will preload 0 - before current, and 1 after the current image
                },
                zoom: {
                    enabled: true,
                    duration: 500 // don't foget to change the duration also in CSS
                }
            });
        }
    }

    slickCarourel() {
        let carousel = $('[data-slick]');
        if (carousel.length > 0) {
            carousel.slick();
        }
    }



    BookingFormDatePicker() {
        $('#check_in_date').datepicker({
            format: 'yyyy-m-d',
            todayHighlight:'TRUE',
            autoclose: true,
            minDate: 0,
            maxDate: '+1Y+6M'

        }).on('changeDate', function (ev) {
                $('#check_in_date').datepicker('setStartDate', $("#check_out_date").val());
                var date = $(this).datepicker('getDate'),
                    day  = date.getDate(),  
                    month = date.getMonth() + 1,              
                    year =  date.getFullYear();
        });

        $('#check_out_date').datepicker({
            format: 'yyyy-m-d',
            todayHighlight:'TRUE',
            autoclose: true,
            minDate: '0',
            maxDate: '+1Y+6M'
            
        }).on('changeDate', function (ev) {
                var start = $("#check_in_date").val();
                var startD = new Date(start);
                var end = $("#check_out_date").val();
                var endD = new Date(end);
                var diff = parseInt((endD.getTime()-startD.getTime())/(24*3600*1000));
                $("#startdate").val(start);
                $("#enddate").val(end);
        });
    };

}